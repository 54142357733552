import React from 'react';
import Head from 'next/head';
import EnplusLayout from '@studionand/depi/src/components/enplus/Layout';
import Header from '@studionand/depi/src/components/enplus/Header';
import StatsSection from '@studionand/depi/src/components/enplus/StatsSection';
import FeatureSection from '@studionand/depi/src/components/enplus/FeatureSection';
import ButtonSection from '@studionand/depi/src/components/enplus/ButtonSection';

const EnplusHolzbrikettsPage = props => {
  return (
    <EnplusLayout context="holzbriketts" hideNavPhotoEdge>
      <Head>
        <title>ENplus Holzbriketts - Startseite</title>
      </Head>
      <Header context="holzbriketts" />
      <StatsSection context="holzbriketts" />
      <ButtonSection />
      <FeatureSection />
    </EnplusLayout>
  );
};

EnplusHolzbrikettsPage.getInitialProps = async ({ dataStore, uiStore }) => {
  await dataStore.pages.fetchFeatures(uiStore.context);
  return {};
};

export default EnplusHolzbrikettsPage;
